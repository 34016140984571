* {
  box-sizing: border-box;
  outline: 0;
}

html {
  font-size: 10px;
  height: 100%;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  height: 100%;
}

input, select, textarea, button {
  font-family: inherit;
  font-size: inherit;
}
