.m1 {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pr2 {
  padding-right: 2rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pl2 {
  padding-left: 2rem;
}

.ptb2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.prl2 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.prbl2 {
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
}
