@media (max-width: 640px) {
  .kapcsolat {
    display: block;

    & tr,
    & td {
      display: block;
    }
  }
}
