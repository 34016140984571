@import '../../../styles/Colors';

.menu {
  a {
    display: block;
    margin: 5px;
    padding: 5px;
    color: black;
    background: white;
    text-decoration: none;
    border-width: 0;
    border-right-width: 5px;
    border-style: solid;
    border-color: $red;

    &:hover,
    &:active,
    &.menu__item_active {
      color: white;
      background: $red;
    }
  }
}
