@import '../../../styles/Colors';

* {
  outline: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background: black;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 21px;
}

h1 {
  font-weight: normal;
  font-size: 40px;
  margin: 0 0 40px;
}

h2 {
  font-size: 30px;
  margin: 0 0 20px;
}

p {
  margin: 20px 0;
  text-align: justify;

  &.margin-bottom-0 {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.bold {
  font-weight: bold;
}
.upcase {
  text-transform: uppercase;
}
.red {
  color: $red;
}
.center {
  text-align: center;
}
.line {
  border-bottom: 1px solid black;
}

td,
th {
  padding: 3px 10px;
  text-align: left;
  vertical-align: top;
}

ul {
  margin-top: 0;

  > li {
    margin: 10px 0;
  }
}

a {
  color: $red;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}
