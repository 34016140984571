.header-wrapper {
  background: rgb(249, 249, 249);
  background: -moz-linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(175, 175, 175, 1) 49%, rgba(140, 140, 140, 1) 50%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(175, 175, 175, 1) 49%, rgba(140, 140, 140, 1) 50%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(175, 175, 175, 1) 49%, rgba(140, 140, 140, 1) 50%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9f9f9",endColorstr="#000000",GradientType=1);
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1280px;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: calc(100% - 200px);

    @media (max-width: 1279.98px) {
      width: 100%;
    }

    img {
      display: block;
      max-width: 100%;
      -webkit-box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.75);
      -moz-box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.75);
      box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.75);
      border: 1px solid white;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;

    @media (max-width: 1279.98px) {
      width: 100%;
    }

    a {
      display: block;

      img {
        display: block;
        max-width: 100%;
        -webkit-filter: drop-shadow(0px 0px 10px #fff);
        filter: drop-shadow(0px 0px 10px #fff);
      }
    }
  }
}
