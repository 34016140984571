.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1280px;
  padding: 5px;

  &__menu {
    width: 200px;

    @media (max-width: 1279.98px) {
      width: 100%;
    }
  }

  &__content {
    width: calc(100% - 200px);

    @media (max-width: 1279.98px) {
      width: 100%;
    }
  }
}
