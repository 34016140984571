.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}
